import React, { useState } from 'react';

type CreatePostVM = {
    title:string,
    content:string
}

export const CreatePost:React.FC = () => {
    const [postData, setPostData] = useState<CreatePostVM>({} as CreatePostVM);

    const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const { name, value} = event.currentTarget;
        setPostData({...postData,[name] : value});
    }

    const handleTextAreaChange = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
        const { name, value} = event.currentTarget;
        setPostData({...postData,[name] : value});
    }

    const handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(postData);
    }
    return(
        <div>
            <h1>Création d'un nouveau post</h1>
            <div className='w-50 mx-auto'>
                <form onSubmit={(e) => handleSubmit(e)} className="justify-content-center">
                    <div  className="mb-3">
                        <label className='form-label'>Titre :</label>
                        <input className='form-control' type="text" onChange={(e) => handleChange(e)} name="title" />
                    </div>
                    <div  className="mb-3">
                        <label className='form-label'>Contenu :</label>
                        <textarea className='form-control' onChange={(e) => handleTextAreaChange(e)} name="content" rows={8} />
                    </div>
                    <input type="submit" className='btn btn-primary' value="Envoyer" />
                </form>
            </div>
        </div>
    );
}