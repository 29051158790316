import { BrowserRouter, Route, Routes } from "react-router-dom";
import './App.css';
import { Footer } from './components/footer';
import NavBar from "./components/header";
import { CreatePost } from './pages/create-post';
import HomePage from './pages/home-page';
import { LoginPage } from "./pages/login-page";
import { NotFoundPage } from './pages/not-found-page';
import { UserProfilePage } from "./pages/user-profile";
import { PostService } from "./services";

export const App: React.FC = () => {
  const homeProps = { postService: new PostService()};
  
  return (
    <BrowserRouter>
      <div className="container text-center">
        <div className="row"> 
          <NavBar />
        </div>
        <div className="row">
          <Routes>
            <Route path="/" element={<HomePage {...homeProps} />} />
            <Route path="/createpost" element={<CreatePost/>} />
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/profile" element={<UserProfilePage/>} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <div className="row">
          <Footer />
        </div>
      </div>
      
    </BrowserRouter>
  );
}

export default App;
