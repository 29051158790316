import React from "react";

export const Menu: React.FC = () => {
    return (
        
            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
                <li><a href="/" className="nav-link px-2 text-secondary">Accueil</a></li>
                <li><a href="/createpost" className="nav-link px-2 text-white">New Post</a></li>
            </ul>
    );
}