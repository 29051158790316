import { useState } from "react";

type LoginVM = {
    username:string,
    password:string
}

export const LoginPage:React.FC = () => {
    const [loginData, setLoginData] = useState<LoginVM>({} as LoginVM);
    
    const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
        const { name, value} = event.currentTarget;
        setLoginData({...loginData,[name] : value});
    }

    const handleSubmit = (event : React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log(loginData);
    }

    return(
        <div className="col-lg-6 offset-md-3">
        <form onSubmit={(e) => handleSubmit(e)}>
            <h1 className="h3 mb-3 fw-normal">Please log in</h1>

            <div className="form-floating">
            <input type="email" onChange={(e) => handleChange(e)} className="form-control" id="floatingInput" name="username" placeholder="name@example.com" autoComplete="off" />
            <label htmlFor="floatingInput">Email address</label>
            </div>
            <div className="form-floating">
            <input type="password" onChange={(e) => handleChange(e)} className="form-control" id="floatingPassword" name="password" placeholder="Password" autoComplete="off" />
            <label htmlFor="floatingPassword">Password</label>
            </div>

            <div className="checkbox mb-3">
            <label>
                <input type="checkbox" value="remember-me" /> Remember me
            </label>
            </div>
            <button className="w-100 btn btn-lg btn-primary" type="submit">Log in</button>
            <p className="mt-5 mb-3 text-muted">© 2023</p>
        </form>
        </div>
    );
}