import React, { Component } from "react";
import Post from '../domain/entities/Post';
import type {IPostService} from "../domain/interfaces/IPostService";
import { PostList } from "../components/post-list";

interface HomeProps {
    postService: IPostService;
}
class HomePage extends Component<HomeProps> {
    
    state = {
        isLoading: true,
        myPosts: [] as Array<Post>,
        error: null
    }

    getFetchPosts() {
        this.setState({loading: true},
            () => {
                this.props.postService.getAllPosts()
                .then(result => this.setState({
                    isLoading: false,
                    myPosts: result
                }))
                .catch(console.log);
            });
    }
    componentDidMount(): void {
        this.getFetchPosts();
    }
    
    render(): React.ReactNode {
        return(
            <>
                <h1>Accueil</h1>

                <PostList posts={this.state.myPosts} />
            </>
        );
    }
} 

export default HomePage;