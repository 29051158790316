import Post from '../domain/entities/Post';
import { IPostService } from '../domain/interfaces/IPostService';
import post from '../data/post.json';

export class PostService implements IPostService {
    public async getAllPosts(): Promise<Post[]> {
        console.log("postServices > getAllPosts called ...");
        
        const jsonResult = post;
        return jsonResult as Array<Post>;
    }
}