import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const Profile = () => {
  const { user, isAuthenticated, logout } = useAuth0();

  return (
    isAuthenticated ? (
      <div className="dropdown show">
          <a className="dropdown-toggle" href="/" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
            <img src={user?.picture} alt={user?.name} width="32" height="32" className="rounded-circle profile-picture" />
          </a>
          <ul className="dropdown-menu text-small" aria-labelledby="dropdownUserMenu">
            <li><a className="dropdown-item" href="./createpost">New post...</a></li>
            <li><a className="dropdown-item" href="/">Settings</a></li>
            <li><a className="dropdown-item" href="./profile">Profile</a></li>
            <li><hr className="dropdown-divider" /></li>
            <li><a className="dropdown-item" href="/" onClick={() => logout({ returnTo: window.location.origin })}>Logout</a></li>
          </ul>
        </div>
    ) : null
  );
};

export default Profile;