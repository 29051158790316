import React from 'react';
import LoginButton from './login-button';
import { useAuth0 } from '@auth0/auth0-react';
import Profile from './profile';

const AuthenticationButton = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? <Profile /> : <LoginButton />;
};

export default AuthenticationButton;