import { Component } from "react";

interface PostCardProps {
    id: number;
    title:string;
    content:string;
}

const styles = {
    img: {
        'width': "300px",
        'margin': "20px 0 20px 0"
    },
};

export class PostCard extends Component<PostCardProps> {
    render(){
        return (
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title">{this.props.title}</h4>
                    <img src="./fake-image-300-225.png" className="card-img-top" style={styles.img} alt="..."></img>
                    <p className="card-text pre-line">{this.props.content.substring(0, 400)}</p>
                    <a href="/" className="card-link">En savoir plus ...</a>
                </div>
            </div>
        )
    }
}