import { Component } from "react";
import Post from "../domain/entities/Post";
import { PostCard } from "./postcard";

interface PostListProps {
    posts: Post[];
}

export class PostList extends Component<PostListProps> {
    render(){
        return (
            <div>
                {this.props.posts && this.props.posts.map(post =>
                    <div className="container col-lg-6 px-4 py-2" key={post.id}>
                        <div className="align-items-center" >
                            <PostCard {...post}/>
                        </div>
                    </div>
                )}
            </div>
        )
    }
}