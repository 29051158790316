import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const UserProfilePage: React.FC = () => {
  const { user, isAuthenticated } = useAuth0();

    return (
        isAuthenticated ? (
            <div className="content-layout">
                <h1 className="content__title">
                Bonjour {user?.name} !
                </h1>
            </div>
        ) : null
    );
}